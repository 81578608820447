import React from 'react';
import { Image404 } from 'components/Image404/Image404';
import { H1 } from 'styles/Typography.styled';
import { Button, Info, PageWrapper, Wrapper } from './NotFoundPageInfo.styled';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';

export const NotFoundPageInfo = () => {
    const isMobile = useBreakpoint('sm');
    return (
        <PageWrapper>
            <Wrapper>
                {isMobile && <Image404 />}
                <H1>Nie znaleziono strony</H1>
                <Info>
                    Przepraszamy, strona, której szukasz, nie została
                    znaleziona. Przejdź do strony głównej, korzystając z
                    przycisku poniżej.
                </Info>
                <Button to="/">Strona główna</Button>
            </Wrapper>
            {!isMobile && <Image404 />}
        </PageWrapper>
    );
};
