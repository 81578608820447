import { mediaQueries } from 'shared/breakpoints';
import styled from 'styled-components';

export const Wrapper = styled.div`
    display: grid;
    grid-gap: 20px;
    place-items: center end;

    ${mediaQueries.sm} {
        margin: 24px 0;
    }
`;
