import React from 'react';

interface Svg404Props {
    width: string;
    height: string;
}

export const Svg404 = ({ width, height }: Svg404Props) => {
    return (
        <svg width={width} height={height} viewBox="0 0 368 132" fill="none">
            <path
                d="M117.414 78.7801H96.8945V55.2001H69.3545V78.7801H35.5145L90.5945 3.00008H59.9945L0.594482 82.9201V102.54H68.4545V129H96.8945V102.54H117.414V78.7801Z"
                fill="#B8C9E0"
            />
            <path
                d="M181.936 131.16C213.076 131.16 235.936 107.58 235.936 66.0001C235.936 24.4201 213.076 0.840088 181.936 0.840088C150.976 0.840088 127.936 24.4201 127.936 66.0001C127.936 107.58 150.976 131.16 181.936 131.16ZM181.936 106.5C167.536 106.5 157.456 94.6201 157.456 66.0001C157.456 37.3801 167.536 25.5001 181.936 25.5001C196.516 25.5001 206.596 37.3801 206.596 66.0001C206.596 94.6201 196.516 106.5 181.936 106.5Z"
                fill="#B8C9E0"
            />
            <path
                d="M367.284 78.7801H346.764V55.2001H319.224V78.7801H285.384L340.464 3.00008H309.864L250.464 82.9201V102.54H318.324V129H346.764V102.54H367.284V78.7801Z"
                fill="#B8C9E0"
            />
        </svg>
    );
};
