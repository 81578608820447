import React from 'react';
import { ImageType } from 'types/CommonTypes';
import {
    ImageMobile,
    LeftWrapper,
    RightWrapper,
    StyledSectionWrapper,
    TextDescription,
    Image,
} from './PromotionsInfo.styled';

import { H2 } from 'styles/Typography.styled';

export interface PromotionInfoProps {
    description: string;
    image: ImageType;
}

export const PromotionInfo = ({ description, image }: PromotionInfoProps) => {
    return (
        <StyledSectionWrapper>
            <LeftWrapper>
                <TextDescription>
                    <div dangerouslySetInnerHTML={{ __html: description }} />
                </TextDescription>
            </LeftWrapper>
            <RightWrapper>
                <Image alt={image?.altText} src={image?.sourceUrl} />
                <ImageMobile alt={image?.altText} src={image?.sourceUrl} />
            </RightWrapper>
        </StyledSectionWrapper>
    );
};
