import React from 'react';
import { Squares } from 'shared/svg/block';
import { Svg404 } from 'shared/svg/svg404';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';
import { Wrapper } from './Image404.styled';

export const Image404 = () => {
    const isMobile = useBreakpoint('xs');
    return (
        <Wrapper>
            <Svg404 width={isMobile ? '260px' : '366px'} height={'130px'} />
            <Squares width={'170px'} height={'114px'} />
        </Wrapper>
    );
};
