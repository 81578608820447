import React from 'react';

interface SquaresProps {
    width: string;
    height: string;
}

export const Squares = ({ width, height }: SquaresProps) => {
    return (
        <svg width={width} height={height} viewBox="0 0 169 114" fill="none">
            <path d="M169 56.5H112.5V113H169V56.5Z" fill="#DBE5F0" />
            <path d="M169 0H112.5V56.5H169V0Z" fill="#F7FAFE" />
            <path d="M112.5 56.5H56V113H112.5V56.5Z" fill="#EDF2F8" />
            <path d="M56.5 57H0V113.5H56.5V57Z" fill="#F7FAFE" />
        </svg>
    );
};
