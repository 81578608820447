import { UniversalHero } from 'components/UniversalHero/UniversalHero';
import * as React from 'react';
import Layout from 'layout/index';
import { PromotionInfo } from 'sections/PromotionsInfo/PromotionsInfo';
import { getDisabledPages } from 'utils/utils';
import { NotFoundPageInfo } from 'sections/NotFoundPageInfo/NotFoundPageInfo';

interface PromotionsPageProps {
    location: {
        pathname: string;
    };
    pageContext: any;
}

export default ({ pageContext, location }: PromotionsPageProps) => {
    const promotionsData =
        pageContext?.data?.wpInvestment?.investment?.promotions;

    const pageTitle = `${pageContext?.title} - ${promotionsData?.title}`;

    if (!pageContext?.data?.wpInvestment?.investment?.enablePromotionsPage) {
        return (
            <Layout
                displayPartnersPage={
                    pageContext?.data?.wpInvestment?.investment
                        ?.enablePartnersPage
                }
                headerData={pageContext?.headerData}
                investmentSlug={pageContext?.slug}
                location={location}
                investmentDisabledPages={getDisabledPages(
                    pageContext?.data?.wpInvestment?.investment,
                )}
            >
                <NotFoundPageInfo />
            </Layout>
        );
    }

    return (
        <Layout
            displayPartnersPage={
                pageContext?.data?.wpInvestment?.investment?.enablePartnersPage
            }
            headerData={pageContext?.headerData}
            investmentSlug={pageContext?.slug}
            pageTitle={pageTitle}
            location={location}
            displayBanner
            investmentDisabledPages={getDisabledPages(
                pageContext?.data?.wpInvestment?.investment,
            )}
        >
            <>
                <UniversalHero
                    background={promotionsData?.titleBackground?.sourceUrl}
                    text={promotionsData?.description}
                    title={promotionsData?.title}
                />
                <PromotionInfo
                    description={promotionsData?.details}
                    image={promotionsData?.image}
                />
            </>
        </Layout>
    );
};
